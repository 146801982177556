<template lang="html">
    <div id="login">
        <div class="main">
            <p>{{$config.websiteTitle}}</p>
            <!-- <img src="../assets/er.png" alt="" class="er" id=""> -->
            <div id="login_container">
				<p class="p1">微信登录</p>
				<img :src="config.qrcode" alt="">
				<div v-if="isMask" class="mask flex flex-column flex-ai-c flex-jc-c">
					二维码已过期
					<p>请<span @click="getConfig()">点击刷新</span></p>
				</div>
				<p class="p2">微信扫描关注公众号登录</p>
            </div>
			<!-- <div class="updateDate" @click="goUpdate">更新日志</div> -->
			<div class="updateDate">
				<el-dropdown trigger="hover" class="msg" style="cursor: pointer;margin-right:50px">
					<span class="el-dropdown-link">
						<i style="color:#fff" class="el-icon-question"></i>
						<span style="margin-left:5px;color:#fff">客户服务</span>
						<i style="color:#fff" class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<div class="flex flex-column flex-ai-c flex-jc-sa">
							<p style="padding:0 10px;text-align:center;line-height:1.5;color:#333;font-family:微软雅黑;">
								客服电话：{{kefu.mobile}}<br>
								或者扫描下方二维码直达客服微信：
							</p>
							<img :src="this.domain+kefu.qrcode" style="width: 200px;padding: 10px;box-sizing: border-box;">
						</div>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
        </div>
		<h4>技术支持&copy;河北织脉云软件开发有限公司</h4>
    </div>
</template>
<script>
// import '../plugins/login.js';
export default {
    data() {
        return {
            config: {},
			timer:null,
			isMask:false,
			kefu:{}
        }
    },
    mounted() {
		this.getConfig();
		this.http.post('/admin.user/help').then(re=>{
			this.kefu=re;
		})
    },

    methods: {
		goUpdate(){
			this.$router.push('/updataShow');
		},
        getConfig() {
            this.http.post('/Login/loginQrcodeGet').then(re => {
				this.config = re;
				this.isMask=false;
				this.timer=setInterval(()=>{
					this.login()
				},3000)
			})
        },
		login(){
			this.http.post('/Login/loginQrcodeCheck',{
				login_str:this.config.login_str
			},false,true).then(re => {
				if(Number(re.code)==200){
					clearInterval(this.timer);
					if(Number(re.data.company_id)==0&&Number(re.data.root_is==0)){
						this.$message({
	                        message: '您尚未注册公司，即将跳往注册申请界面...',
	                        type: 'warning'
	                    });
	                    setTimeout(()=> {
							this.$router.push({
								name:'注册',
								params:{
									form:'login'
								}
							});
	                    }, 1500)
					}else{
						// this.$message({
	                    //     message: '登录成功',
	                    //     type: 'success'
	                    // });
						if(Number(re.data.root_is)==0){
							this.$router.push({
								name:'可视化统计',
								params:{
									form:'login'
								}
							});
						}else{
							this.$router.push({
								name:'商户管理',
								params:{
									form:'login'
								}
							});
						}
						sessionStorage.setItem('userId',re.data.id)
					}
				}else if(Number(re.code)==600){
					this.isMask=true;
					clearInterval(this.timer);
				}else if(Number(re.code)==601){
					return false
				}else{
					clearInterval(this.timer);
					this.$message.error(re.message);
				}
            })
		},
    }
};
</script>
